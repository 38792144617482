html {
    scroll-behavior: smooth;
}

.progress-bar {
    position: fixed;
    top: 100;
    left: 0;
    right: 0;
    height: 5px;
    background: #3282B8;
    transform-origin: 0%;
    z-index: 51;
}